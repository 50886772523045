.material-list {
    column-count: 1;
    column-gap: 2em;
}
.summary-list-booking li {
    break-inside: avoid-column;
}
.summary-list-booking {
    column-count: 1;
    column-gap: 2em;
}

@media screen and (min-width: 901px) {
    .material-list {
        column-count: 2;
    }
    .summary-list-booking {
        column-count: 2;
    }
}