body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.navigation-progress {
    text-align: center;
    width: 100%;
    padding: 1em 2em;
}


/*desktop screen*/
@media only screen and (min-width: 901px) {
    .navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navigation-progress {
        text-align: center;
    }

    .navigation-buttons {
        display: flex;
        justify-content: center;
    }

    .form-container {
        min-height: 75vh;
        max-height: 90vh;
        width: 60vw;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 20%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        /*border: 3px solid #eac764;*/
        border-radius: 40px !important;
        overflow: scroll !important;
    }
}

/*mobile view landscape*/
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .navigation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }


    .navigation-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 2em;
        padding-right: 2em;
    }

    .form-container {
        height: 98%;
        width: 97%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 1%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        /*background-color: rgba(255, 255, 255, 0) !important;*/
        /*border: 3px solid #eac764;*/
        /*border-radius: 10px !important;*/
        overflow: scroll !important;
        /*    make the form background transparent*/
        background-color: rgba(255, 255, 255, 0) !important;

    }
}


/*mobile view*/
@media only screen and (max-width: 600px) {
    .navigation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .navigation-progress {
        text-align: center;
        width: 100%;
    }

    .navigation-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 2em;
        padding-right: 2em;
    }


    .form-container {
        height: 98%;
        height: var(--doc-height);
        width: 98%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 2px;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        /*background-color: rgba(255, 255, 255, 0) !important;*/
        /*border: 3px solid #eac764;*/
        border-radius: 20px !important;
        overflow: scroll !important;
    }
}

.div-password-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.div-password-input input {
    -webkit-box-shadow: None !important;
}
