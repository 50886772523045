.timeslot-list {
    column-count: 1;
    column-gap: 2em;
}

@media screen and (min-width: 901px) {
    .timeslot-list {
        column-count: 2;
        justify-content: center;
    }
}

.jellyfish {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.jellyfish-animate {
    transform: scale(1.1);
    opacity: 0.8;
}

.gelatine {
  animation: gelatine 0.5s infinite;
}
@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.8, 1.3); }
  50% { transform: scale(1.3, 0.8); }
  75% { transform: scale(0.8, 1.3); }
}

