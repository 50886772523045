.avatar-container {
  position: relative;
  padding: 2em 1em;
}

.porthole-image {
  position: absolute;
  top: 13px;
  left: 0;
  z-index: 1; /* Ensure it's on top */
  width: 80%; /* Adjust sizing as needed */
}
